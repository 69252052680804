import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    container: {
        padding: '22px 0px'
    },
    listAndCardRow: {
        paddingBottom: 16
    },
    row: {
        paddingTop: 16
    },
    rowButtons: {
        paddingTop: 22
    },
    title: {
        fontSize: 20,
        lineHeight: '24px',
        color: '#172B4D',
        fontFamily: 'SF UI Text Medium',
        marginBottom: 16
    },
    requiredText: {
        fontSize: 14,
        lineHeight: '20vpx',
        color: '#7A869A',
        fontFamily: 'SF UI Text Medium',
        marginTop: 34,
        '& span': {
            color: '#DE350B'
        }
    },
    workDescription: {
        height: '120px !important'
    },
    loadingSpinner: {
        flexGrow: 1,
        textAlign: 'right',
        paddingTop: 8
    },
    cancelButton: {
        marginRight: 8
    },
    crossIcon: {
        float: 'right'
    },
    messageContainer: {
        textAlign: 'start',
        marginBottom: 10
    },
    billable: {
        color: '#172B4D',
        fontFamily: 'SF UI Text Regular',
        fontSize: 14
    },
    billableContainer: {
        marginBottom: 6,
        marginLeft: 8
    }
})

export {useStyles}
