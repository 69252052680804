import {useEffect} from 'react'
import {initializeCapabilities, PageView, TrackActionEvent,} from 'trello-shared-resources'

/**
 *  The ConnectorView is used to tell Trello what capabilities your
 *  app should have. Use this Connector to add new menu items, buttons
 *  and other similar capabilities.
 *
 *  TODO - Ideally, this should be in the shared library, however it references back to a
 *  component (AppConnector) that exists in this plugin, so we'd need to understand a bit more
 *  about how that works.
 */
const ConnectorView = () => {
    const capabilities = {
        'card-back-section': function (t, opts) {
            return {
                title: 'Time Tracking and Reporting',
                icon: './icons/tt-logo-grey.svg',
                content: {
                    type: 'iframe',
                    url: t.signUrl('./index.html?apptype=card-back-section'),
                    height: 50
                }
            }
        },
        // Creates one or more buttons on the board (top-right)
        'board-buttons': function (t, options) {
            return [{
                text: 'Time Tracking Reports',
                icon: './icons/tt-logo-white.svg',
                // Board buttons need their own modal function
                callback: function (t, opts) {
                    const trelloContextInfo = t.getContext()
                    TrackActionEvent('Button Click', trelloContextInfo, {
                        board_id: trelloContextInfo.board,
                        card_id: null,
                        button: 'Open find_logs'
                    })
                    return t.modal({
                        url: './index.html?apptype=report',
                        accentColor: '#EBEDF0',
                        fullscreen: true,
                        resizable: false,
                        title: 'Time Tracking and Reporting by Orah Apps',
                        callback: function (t, opts) {
                            const trelloContextInfo = t.getContext()
                            TrackActionEvent('Button Click', trelloContextInfo, {
                                board_id: trelloContextInfo.board,
                                card_id: null,
                                button: 'Close find_logs'
                            })
                        }
                    })
                }
            }]
        }
    }

    useEffect(() => {
        PageView('PowerUp Connector')
    }, [])

    initializeCapabilities(capabilities)

    return null
}

export default ConnectorView