/**
 * Get date format string based on user locale
 */
import {toInteger} from 'lodash'
import {LogWorkTimeData, ReportData} from "../types/ReportProperties";
import Member from 'trello-shared-resources/dist/types/Member'
import {SelectOptionValue} from '../types/LogProperties'
import {WeekStartDay} from "../types/WeekStartDay";
import {TimeEntry} from '../types/Timer'

export function getDateFormatString() {
    const formatObj = new Intl.DateTimeFormat().formatToParts(new Date())
    return formatObj
        .map(obj => {
            switch (obj.type) {
                case "day":
                    return "DD"
                case "month":
                    return "MM"
                case "year":
                    return "YYYY"
                default:
                    return obj.value
            }
        })
        .join("")
}

/**
 * Get the user locale defined in the browser
 */
export function getUserLocale() {
    return new Intl.DateTimeFormat().resolvedOptions().locale
}

/**
 * Returns the correct text based on the number of elements. If there is one unit, it returns the singular text, else plural
 */
export const getTextFromNo = (noOfElements: number, baseText: string) => {
    return noOfElements === 1 ? `${noOfElements} ${baseText}` : `${noOfElements} ${baseText}s`
}

/**
 * Calculate the number of seconds by the given time
 * @param time an hour and minute in HH:mm format
 * @return the number of seconds
 */
export function convertTimeDurationToSeconds(time: string | undefined): number {
    if (!time || time === '') return 0
    const timeParts = time.split(':')
    if (timeParts.length !== 2) return 0
    return parseInt(timeParts[0]) * 3600 + parseInt(timeParts[1]) * 60
}

/**
 * Calculate the time duration in HH:MM format, given the seconds by params
 * @param seconds
 * @return the time duration in HH:MM or '' if there is no seconds or seconds is 0
 */
export function convertSecondsToTimeDuration(seconds: number | undefined): string {
    if (!seconds || seconds === 0 || seconds < 0) return ''

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

/**
 * Calculate the time duration in 00h 00m format, given the seconds by params
 * @param seconds
 * @return the time duration in 00h 00m or '' if there is no seconds or seconds is 0
 */
export function convertSecondsToWorklogDuration(seconds: number | undefined): string {
    if (!seconds || seconds === 0 || seconds < 0) return ''

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const hoursToDisplay = hours > 0 ? `${hours}h`: ''
    const minutesToDisplay = minutes > 0 ? `${minutes}m` : ''
    const separator = hours > 0 && minutes > 0 ? ' ' : ''
    return `${hoursToDisplay}${separator}${minutesToDisplay}`
}

/**
 * Calculate the seconds given a time duration in 00h 00m format
 * @param worklogDuration a time in 00h 00m format introduced by the user, like 1h 30m
 * @return the calculated seconds
 */
export function convertWorklogDurationToSeconds(worklogDuration: string | undefined): number {
    if (!worklogDuration || worklogDuration === '') return 0

    const worklogParts = worklogDuration.split('h').map(worklogPart =>
        worklogPart.includes('m') ? worklogPart.trim().replace('m','') : worklogPart.trim())

    if(worklogParts.find(worklogPart => isNaN(Number(worklogPart)))) return 0
    if(worklogParts.length === 2) {
        return toInteger(worklogParts[0]) * 3600 + toInteger(worklogParts[1]) * 60
    } else if(worklogParts.length === 1){
        if(worklogDuration.includes('h') || (!worklogDuration.includes('h') && !worklogDuration.includes('m'))){
            return toInteger(worklogParts[0]) * 3600
        }else {
            return toInteger(worklogParts[0]) * 60
        }
    }
    return 0
}

/**
 * Calculate the difference between the given start and end time
 * @param startDate
 * @param startTime
 * @param endDate
 * @param endTime
 * @return the duration in Xh Ym format, eg 1h 30m
 */
export function calculateTimeDurationDiff(startDate: string, startTime: string, endDate: string, endTime: string): string {
    if (!startTime || !endTime) return ''

    const dateStart = startDate ? new Date(startDate) : (endDate ? new Date(endDate) : new Date())
    const dateEnd = endDate ? new Date(endDate) : (startDate ? dateStart : new Date())
    if(dateStart > dateEnd) return ''

    const secondsStartTime = convertTimeDurationToSeconds(startTime)
    const secondsEndTime = convertTimeDurationToSeconds(endTime)
    if(dateStart.getTime() === dateEnd.getTime() && secondsStartTime === secondsEndTime) return '0h'
    if(dateStart.getTime() === dateEnd.getTime() && secondsStartTime > secondsEndTime) return ''

    const secondsTotalTime = secondsEndTime - secondsStartTime
    // @ts-ignore
    const dateDiffMillis = dateEnd - dateStart
    return convertSecondsToWorklogDuration((dateDiffMillis / 1000) + secondsTotalTime)
}

/**
 * Get the string date in YYYY-MM-DD format
 * @param date
 * @return String with the date or empty string if date doesn't exist
 */
export function dateToString(date: Date | undefined): string {
    return date ? date.toISOString().slice(0, 10) : ''
}


/**
 * Add unit time value to a param value
 * @param value value to transform
 */
export function addUnitTimeCharacter(value: string) {

    if (!value) {
        return value
    }

    const valueWithoutSpaces = value.replaceAll(/\s/g, '')

    if (valueWithoutSpaces === '') {
        return value
    }

    if (!isNaN(Number(value))) {
        return valueWithoutSpaces.concat('h')
    }

    const isHoursAndMinutes = new RegExp(/^\d+h\d+m$/).test(valueWithoutSpaces)

    if (isHoursAndMinutes) {
        return valueWithoutSpaces.replace('h', 'h ')
    }
    const isHoursOrMinutes = new RegExp(/(^ *\d+ *h *$)|(^ *\d+ *m *$)/).test(value)

    if (isHoursOrMinutes) {
        return valueWithoutSpaces
    }

    return value
}

/**
 * Validate total time:
 *      - Is required
 *      - Must follow the pattern: 0h 0m / 0h / 0m
 * @param value total time introduced by the user
 * @return 'EMPTY' if there is no value, 'INVALID_TOTAL_TIME' if doesn't match the pattern or undefined if is valid
 */
export const validateTotalTimeFormat = (value: string | undefined) => {
    if (!value) return 'EMPTY'
    const totalTimeWithUnits = addUnitTimeCharacter(value)
    const regexp = new RegExp(/^\d+h \d+m$|^\d+h$|^\d+m$/)
    return (!regexp.test(totalTimeWithUnits) ? 'INVALID_TOTAL_TIME' : undefined)
}

/**
 * Generate a member name based on their data provided by params
 * @param member object that represents a Trello user
 * @return string with the user's full name + username
 */
export const getMemberName = (member: Member) => member?.fullName || member?.username ? `${member.fullName ?? ''} (${member.username ?? ''})` : ''

/**
 * Validate list field:
 *     - Is required
 *     - If there are any list
 * @param value list selected by the user
 * @param lists list loaded
 * @return 'EMPTY' if there is no value, 'NOT_FOUND_LISTS' if there are any loaded list or undefined if is valid
 */
export const validateList = (value: SelectOptionValue | undefined, lists: Array<any>) => {
    if (!lists || lists.length === 0) {
        return 'NOT_FOUND_LISTS'
    }
    if (!value) return 'EMPTY'
    return undefined
}

/**
 * A user can perform delete/edit actions to its own time logs or when is an admin
 * @param worklog worklog information
 * @return true if the user is the owner of the worklog or if it's an admin role, false otherwise
 */
export const canCurrentUserPerformActions = (worklog: LogWorkTimeData | ReportData, currentMember: Member | undefined) => {
    return (currentMember && worklog?.member && currentMember.id === worklog.member.id) || (currentMember && currentMember.memberType === 'admin')
}

/**
 * Throw a general error boundary and resize the PU automatically
 * More details https://github.com/bvaughn/react-error-boundary
 * @param errorHandlerFunction error handler function from hook useErrorHandler() in the component
 * @param trelloIframeContext trello context Pu
 * @param container name container where the component is to resize, size or document.body
 */
export const throwError = (errorHandlerFunction: any, trelloIframeContext: any, container: any) => {
    trelloIframeContext.sizeTo(container)
    errorHandlerFunction.call()
}

/**
 * Calculate the week start date based on the given region and language
 * @param region
 * @param language
 * @return a WeekStartDay value
 */
const weekStart = (region: string, language: string) : WeekStartDay => {
    const regionSat = 'AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g)
    const regionSun = 'AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPTPYSASGSVTHTTTWUMUSVEVIWSYEZAZWGB'.match(/../g)
    const languageSat = ['ar','arq','arz','fa']
    const languageSun = 'amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu'.match(/../g)

    if(region) {
        return regionSun!.includes(region) ? WeekStartDay.SUNDAY : regionSat!.includes(region) ? WeekStartDay.SATURDAY : WeekStartDay.MONDAY
    } else {
        return languageSun!.includes(language) ? WeekStartDay.SUNDAY : languageSat.includes(language) ? WeekStartDay.SATURDAY : WeekStartDay.MONDAY
    }
}

/**
 * Calculate the week start date based on the given locale
 * @param locale user locale
 * @return a WeekStartDay value or WeekStartDay.Sunday in case there is no locale provided or doesn't match the RE
 */
export const weekStartLocale = (locale: string): WeekStartDay => {
    const parts = locale?.match(/^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i)
    if(!parts) return WeekStartDay.SUNDAY
    return weekStart(parts[4], parts[1])
}

/**
 * Get total seconds that a timer has been running
 * @return number of seconds
 */
export const getTotalTimerDuration = (timeEntries: Array<TimeEntry>): number => {
    let totalSeconds = 0
    timeEntries?.forEach(timeEntry => {
        if(timeEntry.endTime) totalSeconds += (timeEntry.endTime) - timeEntry.startTime
        else totalSeconds += new Date().getTime() - timeEntry.startTime
    })
    return Math.round(totalSeconds / 1000)
}