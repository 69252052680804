import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import VidPauseIcon from '@atlaskit/icon/glyph/vid-pause'
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import {CustomThemeButton} from '@atlaskit/button'
import React, {useCallback, useEffect, useState} from 'react'
import {timerButtonTheme, useStyles} from './TimerDisplayStyles'
import {useStopwatch} from 'react-timer-hook'
import ButtonGroup from 'antd/lib/button/button-group'
import {Box, Grid} from '@material-ui/core'
import * as Persistence from '../../../modules/Persistence'
import {getTimerForCard, startTimer, stopTimer} from '../../../modules/Persistence'
import {TimerProps} from '../../../types/TimerProps'
import {getTotalTimerDuration, throwError} from '../../../modules/Utils'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import {Timer} from '../../../types/Timer'
import Spinner from '@atlaskit/spinner'
import CancelModal from '../../common/CancelModal'
import {useErrorHandler} from 'react-error-boundary'

export const TimerDisplay = (props: TimerProps) => {

    const classes = useStyles()

    const {licenseDetails} = props

    const [timerHasBeenInitialized, setTimerHasBeenInitialized] = useState<boolean>(false)
    const [existingTimer, setExistingTimer] = useState<Timer | undefined>(undefined)
    const [isloading, setIsLoading] = useState<boolean>(true)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
    const [isFinishModalOpen, setIsFinishModalOpen] = useState<boolean>(false)
    const handleError = useErrorHandler()

    const sizeToModal = 350
    const sizeWithoutModal = 50

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({})

    const loadTimerData = useCallback(() => {
        getTimerForCard(licenseDetails).then(timer => {
            setIsLoading(false)
            setExistingTimer(timer)
            if (timer) {
                setTimerHasBeenInitialized(true)
                const time = new Date()
                time.setSeconds(time.getSeconds() + getTotalTimerDuration(timer.times))
                const timerRunning = timer.times.some(timeEntry => timeEntry.startTime && !timeEntry.endTime)
                reset(time, timerRunning)
            }
        }).catch(() => {
            setIsLoading(false)
            setTimerHasBeenInitialized(false)
            setExistingTimer(undefined)
        })
    }, [licenseDetails, reset])

    useEffect(() => {
        if (!existingTimer) {
            loadTimerData()
        }
    }, [loadTimerData, existingTimer])

    const startTimerOnClick = async () => {
        console.log('starting...')
        start()
        const timer = await startTimer(licenseDetails)
        setExistingTimer(timer)
        trackTimerAction('start')
        setTimerHasBeenInitialized(true)
    }

    const pauseOnClick = async () => {
        pause()
        const timer = await stopTimer(licenseDetails)
        setExistingTimer(timer)
        trackTimerAction('pause')
    }

    const cancelOnClick = () => {
        setIsCancelModalOpen(true)
        licenseDetails.trelloIframeContext.sizeTo(sizeToModal)
    }

    const finishOnClick = () => {
        setIsFinishModalOpen(true)
        licenseDetails.trelloIframeContext.sizeTo(sizeToModal)
    }

    const confirmModalCancel = async () => {
        setTimerHasBeenInitialized(false)
        licenseDetails.trelloIframeContext.sizeTo(sizeWithoutModal)
        await Persistence.cancelTimer(licenseDetails)
        reset(undefined, false)
        trackTimerAction('cancel')
    }

    const finishModalCancel = async () => {
        setTimerHasBeenInitialized(false)
        licenseDetails.trelloIframeContext.sizeTo(sizeWithoutModal)
        await Persistence.finishTimer(licenseDetails)
        reset(undefined, false)
        trackTimerAction('finish')
    }

    const closeModalCancel = () => {
        setIsCancelModalOpen(false)
        licenseDetails.trelloIframeContext.sizeTo(sizeWithoutModal)
    }

    const closeModalFinish = () => {
        setIsFinishModalOpen(false)
        licenseDetails.trelloIframeContext.sizeTo(sizeWithoutModal)
    }

    const trackTimerAction = (action: string) => {
        const trelloContextInfo = licenseDetails.trelloIframeContext.getContext()
        TrackActionEvent('Timer', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            card: trelloContextInfo.card,
            action: action
        })
    }


    return <>
        <Grid item className={classes.timerContainer}>
            {isloading && <Box className={classes.spinnerContainer}><Spinner/></Box>}
            {!timerHasBeenInitialized && !isloading &&
                <CustomThemeButton className={classes.startTimerButton} theme={timerButtonTheme}
                                   onClick={startTimerOnClick}
                                   iconAfter={<VidPlayIcon label={'Start timer'} primaryColor={'#FFFFFF'}/>}>
                    Start timer
                </CustomThemeButton>
            }
            {timerHasBeenInitialized &&
                <ButtonGroup className={classes.timerButtonGroupContainer}>
                    <Box className={classes.counterContainer}>
                        {(days * 24 + hours).toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                    </Box>
                    {isRunning && <CustomThemeButton theme={timerButtonTheme} onClick={pauseOnClick}
                                                     iconAfter={<VidPauseIcon label={'Pause timer'}
                                                                              primaryColor={'#FFFFFF'}
                                                                              size={'small'}/>}>
                        Pause
                    </CustomThemeButton>}
                    {!isRunning && <CustomThemeButton theme={timerButtonTheme} onClick={start}
                                                      iconAfter={<VidPlayIcon label={'Resume timer'}
                                                                              primaryColor={'#FFFFFF'}
                                                                              size={'small'}/>}>
                        Resume
                    </CustomThemeButton>}
                    <CustomThemeButton theme={timerButtonTheme} onClick={cancelOnClick}
                                       iconAfter={<CrossCircleIcon label={'Cancel timer'} primaryColor={'#FFFFFF'}
                                                                   secondaryColor={'#5243AA'} size={'small'}/>}>
                        Cancel
                    </CustomThemeButton>
                    <CustomThemeButton theme={timerButtonTheme} onClick={finishOnClick}
                                       iconAfter={<CheckCircleIcon label={'Finish timer'} primaryColor={'#FFFFFF'}
                                                                   secondaryColor={'#5243AA'} size={'small'}/>}>
                        Finish
                    </CustomThemeButton>
                </ButtonGroup>
            }

        </Grid>
        <CancelModal
            isCancelModalOpen={isCancelModalOpen}
            setIsCancelModalOpen={setIsCancelModalOpen}
            confirmAction={() => confirmModalCancel().catch((error: any) =>
                throwError(() => handleError(error), props.licenseDetails.trelloIframeContext, '#contentCardBackSection'))}
            closeAction={closeModalCancel}
            cancelAction={closeModalCancel}
            titleText="Cancel Timer"
            mainBodyText="Are you sure you want to cancel the timer?"
            cancelButtonText="No, go back"
            okButtonText="Yes, cancel"
        />
        <CancelModal
            isCancelModalOpen={isFinishModalOpen}
            setIsCancelModalOpen={setIsFinishModalOpen}
            confirmAction={() => finishModalCancel().catch((error: any) =>
                throwError(() => handleError(error), props.licenseDetails.trelloIframeContext, '#contentCardBackSection'))}
            closeAction={closeModalFinish}
            cancelAction={closeModalFinish}
            titleText="Log Time"
            mainBodyText="Are you sure you want to stop the timer and log this time?"
            cancelButtonText="No, go back"
            okButtonText="Yes, log time"
        />
    </>
}