import React, {useEffect, useRef} from 'react'
import {Grid, Typography} from '@material-ui/core'
import {CheckboxField, ErrorMessage, Field} from '@atlaskit/form'
import {Checkbox} from '@atlaskit/checkbox'
import {requiredValidation} from 'trello-shared-resources/dist/modules/utility/Validations'
import {
    addUnitTimeCharacter,
    calculateTimeDurationDiff,
    convertTimeDurationToSeconds,
    convertWorklogDurationToSeconds,
    dateToString,
    validateTotalTimeFormat, weekStartLocale
} from "../../modules/Utils"
import Textfield from "@atlaskit/textfield"
import {useStyles} from "./LogWorkTimeStyles"
import {DatePicker, TimePicker} from "@atlaskit/datetime-picker"
import {DateAndTimeWorklogFieldProperties} from "../../types/LogProperties"
import {Meta} from "@atlaskit/form/Field"


export const DateAndTimeWorklogFields = (props: DateAndTimeWorklogFieldProperties) => {

    const classes = useStyles()

    const {
        startDateValue, setStartDateValue, endDateValue, setEndDateValue, startTimeValue, setStartTimeValue,
        endTimeValue, setEndTimeValue, totalTimeValue, setTotalTimeValue, billable
    } = props

    const startDate = useRef<Date | undefined>(undefined)
    startDate.current = startDateValue !== '' ? new Date(startDateValue) : undefined
    const endDate = useRef<Date | undefined>(undefined)
    endDate.current = endDateValue !== '' ? new Date(endDateValue) : undefined

    const startTime = useRef<string | undefined>(undefined)
    startTime.current = endDateValue !== '' ? endDateValue : undefined
    const endTime = useRef<string | undefined>(undefined)
    endTime.current = endTimeValue !== '' ? endTimeValue : undefined

    const totalTime = useRef<string | undefined>(totalTimeValue)

    const locale = Intl.NumberFormat().resolvedOptions().locale

    const dateBeforeErrorMessage = <ErrorMessage>The start date must be before, or the same, as the end
        date</ErrorMessage>

    const timeBeforeErrorMessage = <ErrorMessage>The start time must be before, or the same, as the end
        time</ErrorMessage>
    const requiredErrorMessage = <ErrorMessage>This field is required</ErrorMessage>

    useEffect(() => {}, [totalTime])

    /**
     * Load a datePicker component
     * @param fieldProps field props from field component related
     * @param customOnChange on change event
     */
    const datePickerField = (fieldProps: any, customOnChange?: any) => (
        <>
            <DatePicker {...fieldProps} testId={fieldProps.name} locale={locale} value={fieldProps.value}
                        weekStartDay={weekStartLocale(locale)} placeholder={new Date().toLocaleDateString(locale)}
                        onChange={(value: any) => {
                if (customOnChange) customOnChange(value)
                fieldProps.onChange(value)
            }}/>
            {fieldProps.error === 'EMPTY' &&
                /* istanbul ignore next */
                requiredErrorMessage}
            {fieldProps.error === 'DATE_BEFORE' &&
                /* istanbul ignore next */
                dateBeforeErrorMessage}
        </>
    )

    /**
     * Load a timePicker component
     * @param fieldProps field props from field component related
     * @param customOnChange on change event
     */
    const timePickerField = (fieldProps: any, customOnChange?: any) => (
        <>
            <TimePicker {...fieldProps} timeFormat="HH:mm" testId={fieldProps.name} timeIsEditable={true}
                        value={fieldProps.value}
                        placeholder={getPlaceholderTimes()}
                        onChange={(value: any) => {
                            if (customOnChange) customOnChange(value)
                            fieldProps.onChange(value)
                        }}/>
            {fieldProps.error === 'EMPTY' &&
                /* istanbul ignore next */
                requiredErrorMessage}
            {fieldProps.error === 'DATE_BEFORE' &&
                /* istanbul ignore next */
                timeBeforeErrorMessage}
        </>
    )

    /**
     * Validate, when selecting the start date, if we have a selected end date, if end date is before start date
     * @param date the start date selected by the user
     * @param formState field values from the form
     * @param fieldState state of the field
     * @return EMPTY if end date has no value, DATE_BEFORE if end date is before start date or undefined otherwise
     */
    const startDateRangeValidation = (date: any, formState: any, fieldState: Meta) => {
        if(!formState.member && !formState.totalTime && !formState.startDate) return undefined // form reset, we don't need to validate
        if (!date && !startDate.current) return 'EMPTY'
        const startDateToCompare = date ? new Date(date) : startDate.current
        if (endDate.current && startDateToCompare && startDateToCompare > endDate.current!) return 'DATE_BEFORE'
        return undefined
    }

    /**
     * Change the start date field and clear start time, end time and total time
     *      For end date, clear it unless the start date is set to today, so end date will be set also to today
     * @param value start date introduced by the user
     */
    const startDateChangeHandler = (value: string) => {
        const today = dateToString(new Date())
        setStartDateValue(value)
        startDate.current = value ? new Date(value) : undefined

        if (value === today) {
            endDate.current = new Date(value)
            setEndDateValue(value)
        } else setEndDateValue('')

        setStartTimeValue('')
        setEndTimeValue('')
        setTotalTimeValue('')
        totalTime.current = undefined
    }

    /**
     * Change the end date and clear end time and total time
     * @param value end date introduced by the user
     */
    const endDateChangeHandler = (value: string) => {
        setEndDateValue(value)
        endDate.current = value ? new Date(value) : undefined
        setEndTimeValue('')
        setTotalTimeValue('')
        totalTime.current = undefined
    }

    /**
     * Calculate and store total time.
     *      If there is no end time, it also try to calculate based on start date + total time filled
     *      Also it updates the end date if needed
     * @param event total time introduced by the user
     */
    const totalTimeChangeHandler = (event: any) => {
        const value = event.currentTarget.value
        setTotalTimeValue(value)
        totalTime.current = value

        let startTimeValueUpdated
        const totalTimeSeconds = convertWorklogDurationToSeconds(value)
        if (startDateValue) {
            const updatedEndDateValue = new Date(startDateValue)

            let startDateValueUpdated
            if (endDateValue && endTimeValue && value && (!startTimeValue || !startDateValue)) {
                startDateValueUpdated = new Date(endDateValue)
                startDateValueUpdated.setSeconds(updatedEndDateValue.getSeconds() + convertTimeDurationToSeconds(endTimeValue) - totalTimeSeconds)
                setStartDateValue(dateToString(startDateValueUpdated))
                startDate.current = startDateValueUpdated
                startTimeValueUpdated = startDateValueUpdated.toISOString().slice(-13, -8)
                setStartTimeValue(startTimeValueUpdated)
            }

            if (startTimeValue) {
                updatedEndDateValue.setSeconds(updatedEndDateValue.getSeconds() + convertTimeDurationToSeconds(startTimeValueUpdated || startTimeValue))
                updatedEndDateValue.setSeconds(updatedEndDateValue.getSeconds() + totalTimeSeconds)
                if(updatedEndDateValue instanceof Date && !isNaN(updatedEndDateValue.getTime()) ) {
                    const endDateValueUpdated = updatedEndDateValue.toISOString().slice(-13, -8)
                    setEndTimeValue(endDateValueUpdated)
                    endTime.current = endDateValueUpdated

                    const daysToAdd = Math.floor(totalTimeSeconds / (24 * 60 * 60))
                    updatedEndDateValue.setDate(updatedEndDateValue.getDate() + daysToAdd)
                    setEndDateValue(dateToString(updatedEndDateValue))
                }
            }
        }
    }

    /**
     * Validate, when selecting the start time, if we have a selected end time, if end time is before start time
     * @param start the start time selected by the user
     * @param formState field values from the form
     * @param fieldState state of the field
     * @return DATE_BEFORE if end time is before start time or undefined otherwise
     */
    const startTimeRangeValidation = (start: any, formState: any, fieldState: Meta) => {
        if(!formState.member && !formState.totalTime && !formState.startDate && !formState.startTime && !formState.endDate) return undefined // form reset, we don't need to validate
        if (endTime.current && (start || startTime.current)) {
            const startTimeToCompare = start ?? startTime.current
            const startDateToCompare = startDate.current
            const [startHours, startMinutes] = startTimeToCompare.split(':')
            startDateToCompare!.setHours(parseInt(startHours), parseInt(startMinutes))
            const endDateToCompare = endDate.current
            const [endHours, endMinutes] = endTime.current.split(':')
            endDateToCompare!.setHours(parseInt(endHours), parseInt(endMinutes))
            if (startDateToCompare && startDateToCompare > endDateToCompare!) return 'DATE_BEFORE'
        }
        return undefined
    }

    /**
     * Store start time and recalculate total time value when a user update start time field
     * @param value start time introduced by the user
     */
    const startTimeChangeHandler = (value: string) => {
        setStartTimeValue(value)
        startTime.current = value
        const calculatedTotalTime = calculateTimeDurationDiff(startDateValue, value, endDateValue, endTimeValue)
        setTotalTimeValue(calculatedTotalTime)
        totalTime.current = calculatedTotalTime
    }

    /**
     * Calculate, store end time and recalculate total time value when a user end start time field
     * @param value end time introduced by the user
     */
    const endTimeChangeHandler = (value: string) => {
        setEndTimeValue(value)
        endTime.current = value
        const calculatedTotalTime = calculateTimeDurationDiff(startDateValue, startTimeValue, endDateValue, value)
        setTotalTimeValue(calculatedTotalTime)
        totalTime.current = calculatedTotalTime
        if (!endDateValue && startDateValue) {
            setEndDateValue(startDateValue)
            endDate.current = new Date(startDateValue)
        }

        if (!startDateValue && endDateValue) {
            setStartDateValue(endDateValue)
            startDate.current = new Date(endDateValue)
        }

        if (!startDateValue && !endDateValue) {
            setStartDateValue(dateToString(new Date()))
            startDate.current = new Date()
            setEndDateValue(dateToString(new Date()))
            endDate.current = new Date()
        }
    }

    /**
     * Generate a midnight time based on the user's locale
     */
    const getPlaceholderTimes = () => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        return date.toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'})
    }

    /**
     * Set total time when focusing out the total time field
     */
    const totalTimeOnBlurHandler = () => {
        const totalTimeWithUnits = addUnitTimeCharacter(totalTimeValue)
        setTotalTimeValue(totalTimeWithUnits)
        totalTime.current = totalTimeWithUnits
    }

    return <>
        <Grid container spacing={2} className={classes.row} justifyContent='flex-start'>
            <Grid item xs={4}>
                <Field label="Start Date" name="startDate" isRequired validate={startDateRangeValidation}>
                    {({
                          fieldProps,
                          error
                      }) => datePickerField({...fieldProps, error, value: startDateValue}, startDateChangeHandler)}
                </Field>
            </Grid>
            <Grid item xs={4}>
                <Field label="End Date" name="endDate" isRequired
                       validate={(value, formState: Object, fieldState: Meta) => !fieldState.dirty ? undefined : requiredValidation(value || endTime.current)}>
                    {({
                          fieldProps,
                          error
                      }) => datePickerField({...fieldProps, error, value: endDateValue}, endDateChangeHandler)}
                </Field>
            </Grid>
            <Grid item container xs={4} alignItems={'flex-end'}>
                <Grid item className={classes.billableContainer}>
                    <CheckboxField name="billable" defaultIsChecked={billable}>
                        {({fieldProps}) => <Checkbox {...fieldProps} label={<Typography
                            className={classes.billable}>Billable</Typography>}/>}
                    </CheckboxField>
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.row} justifyContent="flex-start">
            <Grid item xs={4}>
                <Field label="Start Time" name="startTime" validate={startTimeRangeValidation}>
                    {({
                          fieldProps,
                          error
                      }) => timePickerField({...fieldProps, error, value: startTimeValue}, startTimeChangeHandler)}
                </Field>
            </Grid>
            <Grid item xs={4}>
                <Field label="End Time" name="endTime">
                    {({
                          fieldProps,
                          error
                      }) => timePickerField({...fieldProps, error, value: endTimeValue}, endTimeChangeHandler)}
                </Field>
            </Grid>
            <Grid item xs={4}>
                <Field label="Total Time" name="totalTime" isRequired validate={() => validateTotalTimeFormat(totalTime.current)}>
                    {({
                          fieldProps,
                          error
                      }) => <>
                        <Textfield {...fieldProps} value={totalTimeValue} placeholder={'00h 00m'}
                                   onBlur={totalTimeOnBlurHandler}
                                   onChange={(value: any) => {
                                       totalTimeChangeHandler(value)
                                       fieldProps.onChange(value)
                                   }}/>
                        {error === 'EMPTY' &&
                            /* istanbul ignore next */
                            requiredErrorMessage}

                        {error === 'INVALID_TOTAL_TIME' &&
                            /* istanbul ignore next */
                            <ErrorMessage>This format is invalid</ErrorMessage>}
                    </>
                    }
                </Field>
            </Grid>
        </Grid>
    </>
}