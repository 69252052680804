import React from 'react'
import Button from '@atlaskit/button'
import { Grid } from '@material-ui/core'
import ExportIcon from '@atlaskit/icon/glyph/export'
import {useStyles} from './CardBackSectionStyles'
import { TrackActionEvent } from 'trello-shared-resources/dist'
import { useErrorHandler } from 'react-error-boundary'
import { throwError } from '../../modules/Utils'
import {TimerDisplay} from './timer/TimerDisplay'

/**
 * Component containing all possible actions a user can perform for the happy paths
 */
const CardBackSectionButtons = (props: { licenseDetails: any }) => {
    const classes = useStyles()
    const {licenseDetails} = props
    const trelloIframeContext = licenseDetails.trelloIframeContext
    const handleError = useErrorHandler()

    const logTimeHandler = () => {
        try {
            const trelloContextInfo = trelloIframeContext.getContext()
            TrackActionEvent('Button Click', trelloContextInfo, {
                board_id: trelloContextInfo.board,
                card_id: trelloContextInfo.card,
                action: 'open',
                button: 'time_tracking'
            })
            trelloIframeContext.modal({
                url: './index.html?apptype=log',
                accentColor: '#EBEDF0',
                fullscreen: false,
                height: 800,
                resizable: false,
                title: 'Time Tracking and Reporting by Orah Apps'
            })
        } catch (error) {
            throwError(() => handleError(error), trelloIframeContext, '#root')
        }
    }
    /**
     * Open the report modal and close the worklog modal from card
     */
    const openReportScreen = () => {
        try {
            const trelloContextInfo = trelloIframeContext.getContext()
            TrackActionEvent('Button Click', trelloContextInfo, {
                board_id: trelloContextInfo.board,
                card_id: trelloContextInfo.card,
                action: 'open',
                button: 'find_logs'
            })
            trelloIframeContext.modal({
                url: './index.html?apptype=report',
                fullscreen: true,
                resizable: false,
                title: 'Time Tracking and Reporting by Orah Apps',
                accentColor: '#EBEDF0'
            })
        } catch (error) {
            throwError(() => handleError(error), trelloIframeContext, '#root')
        }
    }

    return <Grid container justifyContent="flex-end">
        <TimerDisplay {...props}/>
        <Button className={classes.actionButton} onClick={openReportScreen}
                iconBefore={<ExportIcon primaryColor={'#42526E'} label="Reports"/>}>Reports</Button>
        <Button className={classes.actionButton} onClick={logTimeHandler} appearance="primary">Log Time</Button>
    </Grid>
}

export default CardBackSectionButtons