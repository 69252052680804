import React from 'react'
import ReactDOM from 'react-dom'

// Import the views your app loads
import Connector from './views/Connector'
import ReportLicenseConsumer from './views/ReportLicenseConsumer'
import LogWork from './views/LogWork'
import CardBackSectionLicenseConsumer from './components/cardBackSection/CardBackSectionLicenseConsumer'
import {appDetailsState, initSentry, LoadAnalytics, Onboarding, writeLog} from 'trello-shared-resources'

// Globally load the ant design css
import 'antd/dist/antd.css'

// Load the main Orah CSS
import './css/main.css'
import 'trello-shared-resources/dist/assets/fonts/font.css'

// Error imports
import ErrorComponent from 'trello-shared-resources/dist/components/onboarding/error/ErrorComponent'
import {ErrorSection} from 'trello-shared-resources/dist/components/onboarding/error/ErrorSection'
import {ErrorBoundary} from 'react-error-boundary'

/*
  Parse query string for "apptype" and route to corresponding view
*/
const queryString = require('query-string')
const parsedQueryString = queryString.parse(window.location.search)
let iframePassedAppType = parsedQueryString['apptype']
writeLog('Iframe was passed apptype : ' + iframePassedAppType)

LoadAnalytics()
initSentry('https://799c2a08a2df4d8e949ffe7c945bc8b3@o82263.ingest.sentry.io/5476367')

function getComponentToRender(iframePassedAppType) {
    switch (iframePassedAppType) {
        case 'log':
            return <LogWork/>
        case 'report':
        case 'settings':
            return <ReportLicenseConsumer selectedMenuItem={iframePassedAppType}/>
        case 'onboarding':
            // This automatically loads when your app is installed
            return <Onboarding context={appDetailsState}/>
        case 'card-back-section':
            return <CardBackSectionLicenseConsumer/>
        case 'error':
            return <ErrorComponent/>
        default: // We not pass a querystring param for the App Connector
            // This connector is used to instantiate your app's capabilities
            return <Connector/>
    }
}

ReactDOM.render(<ErrorBoundary fallbackRender={({error, resetErrorBoundary}) =>
        <ErrorSection errorMessage={error.message} setErrorMessage={resetErrorBoundary}
                      smallWindow={iframePassedAppType === 'card-back-section'}/>}> {getComponentToRender(iframePassedAppType)} </ErrorBoundary>
    , document.getElementById('root')
)