import {LogWorkInput, WorkLogStored} from '../types/LogProperties'
import TrelloUrlBuilder from 'trello-shared-resources/dist/modules/url/TrelloUrlBuilder'
import TrelloEntityType from 'trello-shared-resources/dist/modules/url/TrelloEntityType'
import {getToken} from 'trello-shared-resources/dist/services/TokenService'
import {getTimeLogEntries} from './Processing'
import {LogTimeData, ReportData} from '../types/ReportProperties'
import {FetchDataError, MessageType} from '../error/FetchDataError'
import * as Sentry from '@sentry/browser'
import Member from 'trello-shared-resources/dist/types/Member'
import Card from 'trello-shared-resources/dist/types/Card'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import apigClientFactory from 'aws-api-gateway-client/dist/apigClient'
import {customerErrorMapping} from '../error/CustomerErrorMapping'
import {convertTimeDurationToSeconds, convertWorklogDurationToSeconds} from './Utils'
import {Timer} from '../types/Timer'

const config = {
    invokeUrl: process.env.REACT_APP_API_DOMAIN_NAME,
    retries: 4,
    retryCondition: (error: any) => error.response && error.response.status === 406,
    retryDelay: 'exponential'
}
export const apigClient = apigClientFactory.newClient(config)
const supportPortalLink = (`<a href="${process.env.REACT_APP_TRELLO_APP_SUPPORT_LINK}" target="_blank">support portal</a>`)
const DATE_PICKER_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

/**
 * Generate authentication headers
 * @param trelloContext to fill all headers
 */
async function generateAuthHeaders(trelloContext: any) {
    return {
        headers: {
            'X-member-id': trelloContext.getContext().member,
            'X-user-token': await trelloContext.jwt(),
            'X-trello-app-token': await getToken(),
            'x-power-up-id': ''
        }
    }
}

/**
 * Get all of the time entries for the card that is 'active' on the given
 * Trello context.
 *
 * @param trelloContext The context that we will use to retrieve data.
 */
export async function getTimeEntriesForCard(licenseDetails: any, members: Member[], otherMembers: Member[], setState: any, card: Card): Promise<ReportData[]> {
    const trelloContext = licenseDetails?.trelloIframeContext
    if (!trelloContext || !trelloContext.getContext() || !trelloContext.getContext().card) {
        const error: string = 'There were an error trying to get worklogs for a card. Try to reload'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/cards/${cardId}/worklogs`

    const additionalParams = await generateAuthHeaders(trelloContext)
    const worklogs = await apigClient.invokeApi({}, pathTemplate, 'GET', additionalParams, {})
        .then((timeEntriesResponse: any) => timeEntriesResponse.data)
    return await getTimeLogEntries(worklogs, members, otherMembers, setState, licenseDetails, [card])
}

/**
 * Generate an object to store the time entry
 * @param trelloContext The context that we will use to retrieve data.
 * @param data info filled by the user to store the time entry
 * @return an object that contains all needed data to be stored
 */
const generateTimeEntryData = async (trelloContext: any, data: LogWorkInput) => {
    const jwt = await trelloContext.jwt()

    return {
        token: jwt,
        memberId: data.member.id,
        creationDate: new Date().valueOf(),
        hoursSpent: convertWorklogDurationToSeconds(data.totalTime),
        cardId: data.card.id,
        boardId: trelloContext.getContext().board,
        listId: data.card.idList,
        currentMember: data.currentMember,
        date: 0,
        startDate: new Date(data.startDate).getTime(),
        endDate: new Date(data.endDate).getTime(),
        startTime: convertTimeDurationToSeconds(data.startTime),
        endTime: convertTimeDurationToSeconds(data.endTime),
        billable: data.billable,
        workDescription: data.workDescription,
        userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
}

/**
 * Store a time entry with the given info
 * @param trelloContext The context that we will use to retrieve data.
 * @param data info filled by the user to store the time entry
 */
export async function storeTimeEntry(trelloContext: any, data: LogWorkInput): Promise<WorkLogStored> {
    if (!trelloContext || !trelloContext.getContext()) {
        const error: string = 'There were an error trying to store a worklog.'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }

    const dataToSend = await generateTimeEntryData(trelloContext, data)
    const pathTemplate = '/worklogs'
    const additionalParams = await generateAuthHeaders(trelloContext)

    return apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, dataToSend)
        .catch((error: any) => {
            Sentry.captureException(error)
            // Translate the Trello error into a customer-friendly output.
            throw new Error(customerErrorMapping(error.message))
        })
        .then((response: any) => response.data)
}

/**
 * Store a time entry with the given info
 * @param trelloContext The context that we will use to retrieve data.
 * @param worklogId The id of the worklog being updated
 * @param data info filled by the user to store the time entry
 */
export async function updateTimeEntry(trelloContext: any, worklogId: string, data: LogWorkInput) {
    if (!trelloContext || !trelloContext.getContext()) {
        const error: string = 'There were an error trying to store a worklog.'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }

    const dataToSend = await generateTimeEntryData(trelloContext, data)
    const pathTemplate = `/worklogs/${worklogId}`
    const additionalParams = await generateAuthHeaders(trelloContext)

    return apigClient.invokeApi({}, pathTemplate, 'PUT', additionalParams, dataToSend)
        .catch((error: any) => {
            Sentry.captureException(error)
            throw new Error(customerErrorMapping(error.message))
        })
}

/**
 * Finds the given entry in the existing list and removes it.
 *
 * @param trelloContext The context provided by trello that we use to read and write data.
 * @param worklogId The ID of the log entry that we want to remove.
 * @param currentMember The current member that is performing the action
 */
export async function removeTimeEntry(trelloContext: any, worklogId: string, currentMember: Member | undefined) {
    if (!trelloContext || !trelloContext.getContext() || !worklogId) {
        const error: string = 'There were an error trying to remove a worklog.'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const pathTemplate = `/worklogs/${worklogId}`
    const additionalParams = await generateAuthHeaders(trelloContext)
    return apigClient.invokeApi({}, pathTemplate, 'DELETE', additionalParams, {currentMember: currentMember})
}

async function get(url: string, throwError: boolean = true, retry = 1): Promise<any> {
    const response = await fetch(url, {method: 'GET'})
    if (response.ok) {
        return await response.json()
    } else {
        if (response.status === 429) {
            await new Promise(resolve => setTimeout(resolve, (retry * 2) * 1000))
            return await get(url, throwError, ++retry)
        } else {
            if (throwError) {
                const message = await response.text()
                throw new FetchDataError('Trello hiccupped.',
                    `Hit us up on our ${supportPortalLink} so we can take a closer look.<p>Status: ${response.status}. Message: ${message}</p>`,
                    MessageType.warning)
            }
        }
    }
}

/**
 * Find all time entries for a board between given dates
 * @param licenseDetails
 * @param dateFrom date to search time entries from
 * @param dateTo date to search time entries to
 * @param members board members to fill data to return
 * @param otherMembers other members to fill data to return
 * @param setState method to update state with new other members
 */
export async function getAllTimeEntries(licenseDetails: any, dateFrom: string, dateTo: string, members: Member[], otherMembers: Member[], setState: any): Promise<LogTimeData> {
    try {
        const url = await getAllCardsUrl(licenseDetails)
        const cards: Card[] = await get(url)

        if (!cards.length) {
            throw new FetchDataError('Whoops!', 'Looks like there are no cards on the board yet.', MessageType.info)
        }

        const trelloContext = licenseDetails.trelloIframeContext
        const boardId = trelloContext.getContext().board

        const from = moment(dateFrom, DATE_PICKER_FORMAT).subtract(12, 'hours').utc().valueOf()
        const to = momentTimezone(dateTo, DATE_PICKER_FORMAT, Intl.DateTimeFormat().resolvedOptions().timeZone).endOf('day').utc().valueOf()

        const pathTemplate = `/boards/${boardId}/worklogs?from=${from}&to=${to}`
        const additionalParams = await generateAuthHeaders(trelloContext)

        const worklogsEntries: WorkLogStored[] = await apigClient.invokeApi({}, pathTemplate, 'GET', additionalParams, {})
            .then((timeEntriesResponse: any) => timeEntriesResponse.data)
            .catch((error: any) => {
                Sentry.captureException(error)
                throw new FetchDataError('Sorry, we couldn’t retrieve your time logs',
                    `Please try again. If you still have problems, please contact our Support team via ${supportPortalLink}`,
                    MessageType.warning)
            })
        const fetchedCards = await getTimeLogEntries(worklogsEntries, members, otherMembers, setState, licenseDetails, cards)

        return {
            reportData: fetchedCards.sort((previous: ReportData, next: ReportData) => {
                const nexStartDate = next.rawStartDate || 0
                const previousStartDate = previous.rawStartDate || 0
                if (nexStartDate === previousStartDate) {
                    return next.loggedHours - previous.loggedHours
                }
                return nexStartDate - previousStartDate
            })
        }
    } catch (error: any) {
        let errorToReport = error

        // handling severe network errors we couldn't handle in the previous calls (e.g. TypeError or Error types)
        if (!(error instanceof FetchDataError)) {
            errorToReport = new FetchDataError('Trello hiccupped.',
                `Hit us up on our ${supportPortalLink} so we can take a closer look.<p>Error: ${error.name}. Message: ${error.message}</p>`,
                MessageType.warning)
        }

        // reporting to Sentry not expected errors
        if (errorToReport instanceof FetchDataError && errorToReport.messageType === MessageType.warning) {
            Sentry.captureException(errorToReport)
        }


        return {
            reportData: [],
            reportDataMessage: errorToReport
        }
    }
}

async function getAllCardsUrl(licenseDetails: any) {
    const trelloContext = licenseDetails.trelloIframeContext
    const board = trelloContext.getContext().board
    const key = licenseDetails.apiKey
    const token = await getToken()

    return new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Boards)
        .withEntityId(board)
        .withEntityAction('cards/all')

        // Search parameters
        .addQueryParameter('sort', '-id')
        .addQueryParameter('fields', 'id,name,shortUrl,labels,url')

        .build()
}

/**
 * Returns member information and member type of the current user
 *
 * @param trelloContext
 */
export async function getCurrentMember(trelloContext: any): Promise<any> {
    if (!trelloContext) {
        const error: string = 'There were an error trying to find current member.'
        Sentry.captureException(error)
        return Promise.reject(error)
    }
    const board = await trelloContext.board('all')
    const currentMember = await trelloContext.member('all')

    const found = board.memberships.find((element: any) => element.idMember === currentMember.id)
    if (found) {
        currentMember.memberType = found.memberType
    }
    return currentMember
}

/**
 * Retrieve all members of the board
 * @param trelloContext current Trello context
 */
export const getBoardMembers = async (trelloContext: any): Promise<any> => {
    if (!trelloContext) {
        const error: string = `There were an error trying to find board's member.`
        Sentry.captureException(error)
        return Promise.reject(error)
    }
    const board = await trelloContext.board('all')
    return board.members
}


/**
 * Find member by the given Id
 * @param licenseDetails
 * @param memberId
 * @return a Promise<Member>
 */
export async function getMemberById(licenseDetails: any, memberId: string): Promise<Member> {
    if (!licenseDetails || !memberId) {
        const error: string = 'There were an error trying to find a member.'
        Sentry.captureException(error)
        return Promise.reject(error)
    }
    const key = licenseDetails.apiKey
    const token = await getToken()

    const getMemberURL = new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Members)
        .withEntityId(memberId)
        .build()

    return get(getMemberURL, false)
}

/**
 * Check if the board does not contains Time tracking old data
 * @param licenseDetails object to get current board and other auth header values
 * @return true if no card on the current board contains old time tracking data or false otherwise
 */
export async function boardCardsContainsOldTimeTrackingData(licenseDetails: any) {
    const powerUpId = licenseDetails.powerUpId
    const cards: any[] = await get(await getAllCardsUrl(licenseDetails))
    return cards.some(card => {
        const pluginData = card.pluginData
        if (!pluginData || pluginData.length === 0 || pluginData[0] === undefined || pluginData[0].idModel === undefined) return false
        const idModel = pluginData[0].idModel
        const worklogsByCard = card.pluginData
            .filter((dataEntry: any) => dataEntry.idPlugin === powerUpId)
            .map((dataEntry: any) => JSON.parse(dataEntry.value))
            .map((worklogs: any) => {
                const entryKey = Object.keys(worklogs).find(entryKey => entryKey === idModel)
                if (entryKey) return worklogs[entryKey]
                else return null
            })
            .filter((worklogs: any) => worklogs)
        return worklogsByCard.length !== 0
    })
}

/**
 * Apply the timezone to a date which is given by param. If the timeZoneName is undefined or invalid, it will apply the browser's timezone to it
 * @param date date to parse
 * @param timeZoneName the name of the timezone worklog
 * @return a string with a language-sensitive representation of the time portion of the date
 */
export const getDateByTimezone = (date: number, timeZoneName: string | undefined) => {
    if (timeZoneName && !/\s/g.test(timeZoneName)) {
        try {
            return new Date(date).toLocaleDateString(undefined, {timeZone: timeZoneName})
        } catch (error) {
            return new Date(date).toLocaleDateString()
        }
    }
    return new Date(date).toLocaleDateString()
}

/**
 * Start timer for the card given by the context
 * @param licenseDetails object to get current board and other auth header values
 * @return the response from the API
 */
export const startTimer = async (licenseDetails: any) => {
    const trelloContext = licenseDetails?.trelloIframeContext
    if (!trelloContext || !trelloContext.getContext() || !trelloContext.getContext().card) {
        const error: string = 'There were an error trying to start timer for a card. Try to reload'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/timer/${cardId}`

    const additionalParams = await generateAuthHeaders(trelloContext)
    return await apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, {})
        .then((timerCreationResponse: any) => timerCreationResponse.data)
        .catch((error: any) => {
            Sentry.captureException(error)
            throw new Error(customerErrorMapping(error.message))
        })
}

/**
 * Get timer for the card given by the context
 * @param licenseDetails object to get current board and other auth header values
 * @return the response from the API
 */
export const getTimerForCard = async (licenseDetails: any): Promise<Timer | undefined> => {
    const trelloContext = licenseDetails?.trelloIframeContext
    if (!trelloContext || !trelloContext.getContext() || !trelloContext.getContext().card) {
        const error: string = 'There were an error trying to get timer for a card. Try to reload'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/timer/${cardId}`

    const additionalParams = await generateAuthHeaders(trelloContext)
    return await apigClient.invokeApi({}, pathTemplate, 'GET', additionalParams, {})
        .then((timerCreationResponse: any) => timerCreationResponse.data)
        .catch((error: any) => {
            Sentry.captureException(error)
            throw new Error(customerErrorMapping(error.message))
        })
}

/**
 * Stop timer for the card given by the context
 * @param licenseDetails object to get current board and other auth header values
 * @return the response from the API
 */
export const stopTimer = async (licenseDetails: any): Promise<Timer | undefined> => {
    const trelloContext = licenseDetails?.trelloIframeContext
    if (!trelloContext || !trelloContext.getContext() || !trelloContext.getContext().card) {
        const error: string = 'There were an error trying to start timer for a card. Try to reload'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/timer/${cardId}/stop`

    const additionalParams = await generateAuthHeaders(trelloContext)
    return await apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, {})
        .then((timerCreationResponse: any) => timerCreationResponse.data)
        .catch((error: any) => {
            Sentry.captureException(error)
            throw new Error(customerErrorMapping(error.message))
        })
}

/**
 * Finds the given timer entry in the existing list and removes it.
 * @param licenseDetails object to get current board and other auth header values
 * @return the response from the API
 */
export async function cancelTimer(licenseDetails: any) {
    const trelloContext = licenseDetails?.trelloIframeContext
    if (!trelloContext || !trelloContext.getContext() || !trelloContext.getContext().card) {
        const error: string = 'There were an error trying to remove a timer.'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/timer/${cardId}`
    const additionalParams = await generateAuthHeaders(trelloContext)
    return apigClient.invokeApi({}, pathTemplate, 'DELETE', additionalParams, {})
        .catch((error: any) => {
            Sentry.captureException(error)
            throw new Error(customerErrorMapping(error.message))
        })
}

/**
 * Finish timer for the card given by the context
 * @param licenseDetails object to get current board and other auth header values
 * @return the response from the API
 */
export const finishTimer = async (licenseDetails: any) => {
    const trelloContext = licenseDetails?.trelloIframeContext
    if (!trelloContext || !trelloContext.getContext() || !trelloContext.getContext().card) {
        const error: string = 'There were an error trying to finish timer for a card. Try to reload'
        Sentry.captureException(error)
        return Promise.reject({message: error})
    }
    const cardId = trelloContext.getContext().card
    const pathTemplate = `/timer/${cardId}/finish`

    const additionalParams = await generateAuthHeaders(trelloContext)
    return await apigClient.invokeApi({}, pathTemplate, 'POST', additionalParams, {})
        .then((timerCreationResponse: any) => timerCreationResponse.data)
        .catch((error: any) => {
            Sentry.captureException(error)
            throw new Error(customerErrorMapping(error.message))
        })
}