import React from 'react'
import { renderTrelloContextMissing } from 'trello-shared-resources/dist/components/powerUp/ModalOutputs'
import CardBackSectionErrorMessage from './CardBackSectionErrorMessage'
import CardBackSectionButtons from './CardBackSectionButtons'

/**
 * Main container component for the section at the back of the card
 */
const CardBackSection = (props: { licenseDetails: any }) => {
    const licenseDetailsContext = props.licenseDetails

    if (!licenseDetailsContext.trelloIframeContext) {
        return renderTrelloContextMissing()
    }

    if (!licenseDetailsContext.isAuthorized || !licenseDetailsContext.isLicensed()) {
        return <CardBackSectionErrorMessage licenseDetails={licenseDetailsContext}/>
    }

    return <CardBackSectionButtons licenseDetails={licenseDetailsContext}/>
}

export default CardBackSection