import React, { useEffect, useState } from 'react'
import LogWorkTime from './LogWorkTime'
import ReportDisplayMessages from '../report/ReportDisplayMessages'
import { Box, Grid, Link } from '@material-ui/core'
import { useStyles } from './LogWorkFromCardStyles'
import { ReportData, ReportDataMessage } from '../../types/ReportProperties'
import { WorklogTable } from './WorklogTable'
import { getTimeEntriesForCard } from '../../modules/Persistence'
import Member from 'trello-shared-resources/dist/types/Member'
import Card from 'trello-shared-resources/dist/types/Card'

const LogWorkFromCard = (props: { licenseDetails: any }) => {
    const classes = useStyles()

    const [reportDataMessage, setReportDataMessage] = useState<ReportDataMessage | undefined>()
    const [worklog, setWorklog] = useState<ReportData | undefined>()
    const [actionPerformed, setActionPerformed] = useState<number>(0)
    const [otherMembers, setOtherMembers] = useState<Member[]>([])
    const [worklogsByCurrentCard, setWorklogsByCurrentCard] = useState<ReportData[]>([])
    const [worklogsIsLoading, setWorklogsIsLoading] = useState<boolean>(true)
    const [createdOrEditedRow, setCreatedOrEditedRow] = useState<string | undefined>()

    const {licenseDetails} = props
    const trelloIframeContext = licenseDetails.trelloIframeContext

    /**
     * Open the report modal and close the worklog modal from card
     */
    const openReportScreen = async () => {
        await trelloIframeContext.modal({
            url: './index.html?apptype=report',
            fullscreen: true,
            resizable: false,
            title: 'Time Tracking and Reporting by Orah Apps',
            accentColor: '#EBEDF0'
        })
        await trelloIframeContext.closeModal()
    }

    /**
     * Refresh this modal
     */
    const incrementActionPerformed = () => {
        setActionPerformed(actionPerformed + 1)
    }

    useEffect(() => {
        trelloIframeContext.card('all')
            .then(async (card: Card) => {
                // @ts-ignore
                setWorklog({
                    card: card
                })
                const board = await trelloIframeContext.board('all')
                const worklogs = await getTimeEntriesForCard(licenseDetails, board.members, otherMembers, setOtherMembers, card)
                setWorklogsByCurrentCard(worklogs)
                setWorklogsIsLoading(false)
                trelloIframeContext.sizeTo('#content')
            })
    }, [trelloIframeContext, licenseDetails, otherMembers, actionPerformed, createdOrEditedRow])

    if (!worklog) {
        return null
    }

    return <Box id="content">
        <Grid className={classes.formContainer}>
            <LogWorkTime licenseDetails={licenseDetails} logWorkFromCard={true}
                         worklog={worklog} incrementActionPerformed={incrementActionPerformed}
                         setCreatedOrEditedRow={setCreatedOrEditedRow}
                         setIsWorklogModalOpen={() => {
                         }} setReportDataMessage={setReportDataMessage}/>
        </Grid>
        <Grid item xs={12} className={classes.messageContainer}>
            <Box>
                <ReportDisplayMessages reportDataMessage={reportDataMessage}
                                       setReportDataMessage={setReportDataMessage}/>
            </Box>
        </Grid>
        <WorklogTable {...props} worklogs={worklogsByCurrentCard}
                      worklogsIsLoading={worklogsIsLoading}
                      setWorklogsIsLoading={setWorklogsIsLoading}
                      setReportDataMessage={setReportDataMessage}
                      incrementActionPerformed={incrementActionPerformed}
                      createdOrEditedRow={createdOrEditedRow}
                      setCreatedOrEditedRow={setCreatedOrEditedRow}/>
        <Grid item xs={12}>
            <Link onClick={openReportScreen} className={classes.reportLink}>Time tracking reports</Link>
        </Grid>
    </Box>
}

export default LogWorkFromCard