import React, { useState } from 'react'
import Button from '@atlaskit/button'
import { useStyles } from './CardBackSectionStyles'
import UnauthorizedStep
    from 'trello-shared-resources/dist/components/onboarding/unauthorized/unauthorizedStep/UnauthorizedStep'
import UnpaidStep from 'trello-shared-resources/dist/components/onboarding/unpaid/unpaidStep/UnpaidStep'

/**
 * Component is showed when a user has not a license or unauthorized
 * */
const CardBackSectionErrorMessage = (props: { licenseDetails: any }) => {
    const classes = useStyles()
    const [isErrorMessageCollapsed, setIsErrorMessageCollapsed] = useState(true)

    const licenseDetailsContext = props.licenseDetails
    const trelloIframeContext = licenseDetailsContext.trelloIframeContext
    licenseDetailsContext.smallErrorMessageWindow = true

    const smallIframeSize: number = 50
    const authorizationOrLicenseFrameSize = 276

    const buttonHandler = () => {
        setIsErrorMessageCollapsed(!isErrorMessageCollapsed)
    }

    const textButton = isErrorMessageCollapsed ? 'Show details' : 'Hide details'
    let mainContent = null
    if (isErrorMessageCollapsed) {
        trelloIframeContext.sizeTo(smallIframeSize)
    } else {
        trelloIframeContext.sizeTo(authorizationOrLicenseFrameSize)
        if (!licenseDetailsContext.isAuthorized) {
            mainContent = <UnauthorizedStep licenseDetails={licenseDetailsContext}/>
        } else if (!licenseDetailsContext.isLicensed()) {
            mainContent = <UnpaidStep licenseDetails={licenseDetailsContext}/>
        }
    }

    return <>
        <Button className={classes.errorButton} onClick={buttonHandler}>{textButton}</Button>
        {mainContent}
    </>
}

export default CardBackSectionErrorMessage